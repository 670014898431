import styled from "styled-components";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";
import { FlexBox } from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import shouldForwardProp from "@pagepro-monorepo/ui/lib/utils/shouldForwardProp";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";

import Link from "@components/atoms/Link";

export const Container = styled(FlexBox).withConfig({
  shouldForwardProp
})`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.secondaryBackground};
  border-bottom-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  position: relative;
  width: 13.375rem;
`;

export const WrapperLink = styled(Link)`
  transition: ${({ theme }) => theme.transition};
  transition-property: opacity;
  outline: 0;

  @media ${media.tablet} {
    &:hover {
      opacity: 0.9;
    }
  }
`;

export const Badge = styled(FlexBox).withConfig({
  shouldForwardProp
})`
  align-items: center;
  border-bottom-left-radius: 50%;
  border-top-right-radius: 50%;
  background-color: #17313b;
  justify-content: center;
  padding: 0.625rem;
  width: 3.125rem;
  height: 3.125rem;
`;

export const Content = styled(FlexBox).withConfig({
  shouldForwardProp
})`
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-width: 9.375rem;
  margin-left: 0.625rem;
`;

export const Header = styled(FlexBox).withConfig({
  shouldForwardProp
})`
  align-items: center;
  justify-content: space-between;
`;

export const Score = styled(Typography).attrs({
  forwardedAs: "p",
  variant: "body11"
})`
  color: #17313b;
  line-height: 1;
  margin-right: 0.5rem;
`;

export const Stars = styled(FlexBox).withConfig({
  shouldForwardProp
})`
  gap: 0.125rem;
`;

export const Description = styled(Typography).attrs({
  forwardedAs: "p",
  variant: "body12"
})`
  color: #17313b;
  letter-spacing: -0.2px;
  margin-top: 0.25rem;
`;
