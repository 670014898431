import React from "react";
import { ReactSVG } from "react-svg";

import LazyHydrate from "@components/utils/LazyHydrate";

import * as Styled from "./styles";
import { ResizableIconProps } from "./types";

const ResizableIcon: React.FC<ResizableIconProps> = ({
  image,
  color,
  fill,
  sizeDesktop,
  sizeTablet,
  sizeMobile
}) => (
  <Styled.Wrapper
    {...{ color, fill, sizeDesktop, sizeTablet, sizeMobile }}
  >
    <LazyHydrate whenVisible>
      <ReactSVG src={image} />
    </LazyHydrate>
  </Styled.Wrapper>
);

export default ResizableIcon;
