/* eslint-disable consistent-return, @typescript-eslint/ban-ts-comment */
import React, { useMemo } from "react";

import { LazyHydrateContextProvider } from "../../contexts/LazyHydrateContext";

type LazyProps = {
  ssrOnly?: boolean;
  whenIdle?: boolean;
  whenVisible?: boolean | IntersectionObserverInit;
  noWrapper?: boolean | keyof JSX.IntrinsicElements;
  didHydrate?: () => void;
  promise?: Promise<unknown>;
  on?: (keyof HTMLElementEventMap)[] | keyof HTMLElementEventMap;
  useDisplayContents?: boolean;
  getEventTarget?: () => EventTarget;
  children: React.ReactElement;
  id?: string;
};

const isBrowser = typeof document !== "undefined";
const isDev = process.env.NODE_ENV !== "development";

type Props = Omit<
  React.HTMLProps<HTMLElement>,
  "dangerouslySetInnerHTML"
> &
  LazyProps;

type VoidFunction = () => void;

// React currently throws a warning when using useLayoutEffect on the server.
const useIsomorphicLayoutEffect = isBrowser
  ? React.useLayoutEffect
  : React.useEffect;

function reducer(): boolean {
  return true;
}

const LazyHydrate: React.FC<Props> = (props) => {
  const childRef = React.useRef<HTMLElement>(null);

  // Always render on server
  const [hydrated, hydrate] = React.useReducer(reducer, !isBrowser);

  const {
    noWrapper,
    ssrOnly,
    whenIdle,
    whenVisible,
    promise, // pass a promise which hydrates
    on = [],
    children,
    didHydrate, // callback for hydration
    useDisplayContents = true,
    id,
    getEventTarget,
    ...rest
  } = props;

  const wrapperElement = useMemo(() => {
    if (!isBrowser) {
      return null;
    }

    return id ? document.getElementById(id) : null;
  }, [id]);

  const wrapperHeight = useMemo(() => {
    if (!wrapperElement) {
      return null;
    }

    return wrapperElement.clientHeight;
  }, [wrapperElement]);

  if (
    isDev &&
    !ssrOnly &&
    !whenIdle &&
    !whenVisible &&
    !on.length &&
    !promise
  ) {
    // eslint-disable-next-line no-console
    console.error(
      `LazyHydration: Enable atleast one trigger for hydration.\n` +
        `If you don't want to hydrate, use ssrOnly`
    );
  }

  useIsomorphicLayoutEffect(() => {
    // No SSR Content
    if (childRef.current && !childRef.current.hasChildNodes()) {
      hydrate();
    }
  }, []);

  React.useEffect(() => {
    if (hydrated && didHydrate) {
      didHydrate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hydrated]);

  React.useEffect(() => {
    if (ssrOnly || hydrated) return;
    const rootElement = childRef.current;

    const cleanupFns: VoidFunction[] = [];

    function cleanup(): void {
      cleanupFns.forEach((fn) => {
        fn();
      });
    }

    if (promise) {
      promise.then(hydrate, hydrate);
    }

    if (whenVisible) {
      const element = noWrapper
        ? rootElement
        : // As root node does not have any box model, it cannot intersect.
          rootElement?.firstElementChild;

      if (element && typeof IntersectionObserver !== "undefined") {
        const observerOptions =
          typeof whenVisible === "object"
            ? whenVisible
            : {
                rootMargin: "250px"
              };

        const io = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting || entry.intersectionRatio > 0) {
              hydrate();
            }
          });
        }, observerOptions);

        io.observe(element);

        cleanupFns.push(() => {
          io.disconnect();
        });
      } else {
        return hydrate();
      }
    }

    if (whenIdle) {
      // @ts-ignore
      if (typeof requestIdleCallback !== "undefined") {
        // @ts-ignore
        const idleCallbackId = requestIdleCallback(hydrate, {
          timeout: 500
        });

        cleanupFns.push(() => {
          // @ts-ignore
          cancelIdleCallback(idleCallbackId);
        });
      } else {
        const timeoutId = setTimeout(hydrate, 2000);

        cleanupFns.push(() => {
          clearTimeout(timeoutId);
        });
      }
    }

    const events = ([] as Array<keyof HTMLElementEventMap>).concat(
      on
    );
    const eventTarget = getEventTarget
      ? getEventTarget()
      : rootElement;

    events.forEach((event) => {
      eventTarget?.addEventListener(event, hydrate, {
        once: true,
        passive: true
      });
      cleanupFns.push(() => {
        eventTarget?.removeEventListener(event, hydrate, {});
      });
    });

    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hydrated,
    on,
    ssrOnly,
    whenIdle,
    whenVisible,
    didHydrate,
    promise,
    noWrapper
  ]);

  const WrapperElement = (typeof noWrapper === "string"
    ? noWrapper
    : "div") as unknown as React.FC<React.HTMLProps<HTMLElement>>;

  if (hydrated) {
    if (wrapperElement) {
      requestAnimationFrame(() => {
        const observer = new MutationObserver(() => {
          if (!wrapperElement.hasChildNodes()) {
            return;
          }

          wrapperElement.style.minHeight = "";
          observer.disconnect();
        });

        observer.observe(wrapperElement, {
          childList: true
        });
      });
    }

    if (noWrapper && typeof noWrapper !== "string") {
      return (
        <LazyHydrateContextProvider value>
          {children}
        </LazyHydrateContextProvider>
      );
    }

    return (
      <LazyHydrateContextProvider value>
        <WrapperElement
          ref={childRef}
          {...{ id }}
          style={
            useDisplayContents ? { display: "contents" } : undefined
          }
          {...rest}
        >
          {children}
        </WrapperElement>
      </LazyHydrateContextProvider>
    );
  }

  if (wrapperHeight !== null && wrapperElement) {
    wrapperElement.style.minHeight = `${wrapperHeight}px`;
  }

  return (
    <LazyHydrateContextProvider value>
      <WrapperElement
        {...rest}
        {...{ id }}
        ref={childRef}
        suppressHydrationWarning
        dangerouslySetInnerHTML={{
          __html: ""
        }}
      />
    </LazyHydrateContextProvider>
  );
};

export default LazyHydrate;
