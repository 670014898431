import { createContext } from "react";

import { noop } from "@utils/noop";

export const ModalContext = createContext<
  () => {
    container: HTMLDivElement | undefined;
    removeContainer: () => void;
  }
>(() => ({ container: undefined, removeContainer: noop }));
