import { getScrollbarWidth } from "./getScrollbarWidth";
import { isSafari } from "./userAgent";

export const disableScrollOnBody = (): void => {
  const { body } = document;

  body.classList.add("has-overflow-hidden");

  if (isSafari()) {
    const scrollBarWidth = getScrollbarWidth();

    body.style.paddingRight = `${scrollBarWidth}px`;
  }
};
