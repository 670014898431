import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";

import { noop } from "@utils/noop";
import { disableScrollOnBody } from "@utils/disableScrollOnBody";
import { enableScrollOnBody } from "@utils/enableScrollOnBody";

import { ModalContext } from "./consts";
import { UseModalContextValueReturnType } from "./types";

export const useModalContextValue =
  (): UseModalContextValueReturnType => {
    const modalContainerRef = useRef<HTMLDivElement>(null);
    const [modalContextValue, setModalContextValue] =
      useState<HTMLDivElement>();

    useEffect(() => {
      const modalNode = modalContainerRef.current;

      setModalContextValue(modalNode || undefined);
    }, []);

    return {
      modalContextValue: useCallback(() => {
        if (!modalContextValue) {
          return {
            container: undefined,
            removeContainer: noop
          };
        }

        const container = document.createElement("div");

        container.setAttribute(
          "style",
          "position: fixed; top: 0; bottom: 0; left: 0; right: 0;"
        );

        modalContextValue.appendChild(container);

        return {
          container,
          removeContainer: () => {
            modalContextValue.removeChild(container);
          }
        };
      }, [modalContextValue]),
      modalContainerRef
    };
  };

export const useBlockBodyWhenModalIsOpened = (
  modalNode: HTMLElement | null
): void => {
  useEffect(() => {
    const observerCallback = (mutations: MutationRecord[]): void => {
      mutations.forEach((mutation) => {
        const modalContainerHasChildren =
          mutation.type === "childList" &&
          !!mutation.addedNodes.length;

        if (modalContainerHasChildren) {
          disableScrollOnBody();
        } else {
          enableScrollOnBody();
        }
      });
    };

    const observer = new MutationObserver(observerCallback);

    if (modalNode) {
      observer.observe(modalNode, { childList: true });
    }

    return (): void => {
      observer.disconnect();
    };
  }, [modalNode]);
};

export const useModalContainer = (): HTMLDivElement | undefined => {
  const getModalContainer = useContext(ModalContext);
  const [modalContainer, setModalContainer] = useState<
    HTMLDivElement | undefined
  >();

  useEffect(() => {
    const getModalContainerResult = getModalContainer();

    setModalContainer(getModalContainerResult.container);

    return getModalContainerResult.removeContainer;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return modalContainer;
};
