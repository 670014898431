import React from "react";

import { HeaderSubmenuProps } from "./types";
import * as Styled from "./styles";
import HeaderSubmenuLink from "./partials/HeaderSubmenuLink";
import useHeaderSubmenuObserver from "./hooks";

const HeaderSubmenu: React.FC<HeaderSubmenuProps> = ({
  submenu = [],
  isScrolled
}) => {
  useHeaderSubmenuObserver(submenu);

  return (
    <Styled.List {...{ isScrolled }}>
      {submenu.map((item) => (
        <HeaderSubmenuLink
          key={item._uid}
          {...{ item, isScrolled }}
        />
      ))}
    </Styled.List>
  );
};

export default HeaderSubmenu;
