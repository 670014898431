import NextImage, { ImageProps } from "next/image";

import { loader } from "./utils";

const Image: React.FC<ImageProps> = ({
  priority,
  height,
  width,
  fill,
  ...rest
}) => {
  const loadingType = priority ? undefined : "lazy";

  return (
    <NextImage
      {...{ loader, priority, fill, ...rest }}
      loading={loadingType}
      height={fill ? undefined : height}
      width={fill ? undefined : width}
    />
  );
};

export default Image;
