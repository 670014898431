import styled from "styled-components";
import { ButtonLink as StyledButtonLink } from "@pagepro-monorepo/ui/lib/components/atoms/Button/styles";

export const ExitPreviewLink = styled(StyledButtonLink)`
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  z-index: 10;
  padding: 1rem;
`;

export const FontStyles = `
  @font-face {
    font-family: "Work Sans";
    src: url("/assets/fonts/worksans/worksans-light.woff2"),
      url("/assets/fonts/worksans/worksans-light.woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Work Sans";
    src: url("/assets/fonts/worksans/worksans-regular.woff2"),
      url("/assets/fonts/worksans/worksans-regular.woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Work Sans";
    src: url("/assets/fonts/worksans/worksans-medium.woff2"),
      url("/assets/fonts/worksans/worksans-medium.woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Work Sans";
    src: url("/assets/fonts/worksans/worksans-semibold.woff2"),
      url("/assets/fonts/worksans/worksans-semibold.woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Galderglynn";
    src: url("/assets/fonts/galderglynn/galderglynn-regular.woff2"),
      url("/assets/fonts/galderglynn/galderglynn-regular.woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Galderglynn";
    src: url("/assets/fonts/galderglynn/galderglynn-bold.woff2"),
      url("/assets/fonts/galderglynn/galderglynn-bold.woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Galderglynn";
    src: url("/assets/fonts/galderglynn/galderglynn-black.woff2"),
      url("/assets/fonts/galderglynn/galderglynn-black.woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
`;
