import React, { PropsWithChildren } from "react";
import NextLink from "next/link";

import { LinkProps } from "./types";
import useIsExternal from "./hooks";

const Link: React.FC<PropsWithChildren<LinkProps>> = ({
  href,
  children,
  ...rest
}) => {
  const isExternalUrl = useIsExternal(href.toString());

  if (isExternalUrl) {
    return (
      <a
        href={href.toString()}
        target="_blank"
        rel="nofollow noopener"
        {...rest}
      >
        {children}
      </a>
    );
  }

  return <NextLink {...{ href, ...rest }}>{children}</NextLink>;
};

export default Link;
