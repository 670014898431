import media from "@pagepro-monorepo/ui/lib/theme/config/media";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

import expandedBg from "public/assets/images/expanded-bg-image.png";
import { IconChevronDown } from "@assets/svg";

import { StyledHeaderNavigationProps } from "./types";

export const ToggleIcon = styled(IconChevronDown)`
  display: none;
  fill: currentColor;
  transition: ${({ theme }) => theme.transition};
  margin-top: 0.1rem;
  margin-left: -0.25rem;
  transform: none;
  @media ${media.tablet} {
    display: block;
  }
`;

export const ExpandContentWrapper = styled.div`
  display: none;
  @media ${media.tablet} {
    display: block;
    overflow: hidden;
    position: absolute;
    left: 0px;
    top: 100%;
    width: 100%;
    transition: ${({ theme }) => theme.transition};
    background-color: ${({ theme }) => theme.colors.white};
    background-image: url(${expandedBg.src});
    background-repeat: no-repeat;
    max-height: 0px;
    color: ${({ theme }) => theme.colors.primary};
    z-index: 1;
    box-shadow: 0px 2px 32px 0px rgba(149, 149, 149, 0.2);
    clip-path: polygon(0% 0%, 100% 0%, 100% 120%, 0% 120%);
  }
`;

export const ListItem = styled.li<
  SpaceProps & StyledHeaderNavigationProps
>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.black};
  font-size: 1.75rem;
  line-height: 1.3;
  transition: ${({ theme }) => theme.transition};
  transform: translateX(100%);
  opacity: 0;
  transition-delay: ${({ index }) => `${index * 150}ms`};
  ${space};
  @media ${media.tablet} {
    transform: none;
    transition-delay: 0ms;
    transition: none;
    opacity: 1;
    justify-content: center;
    font-family: ${({ theme }) => theme.fonts.body};
    font-size: 0.875rem;
    line-height: 1.2;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    &:not(:last-of-type) {
      margin-right: 0.5rem;
    }
  }
  &:hover {
    color: ${({ theme }) => theme.colors.brand};
    ${ExpandContentWrapper} {
      max-height: 700px;
    }
    ${ToggleIcon} {
      transform: rotate(180deg);
    }

    &:after {
      display: ${({ isExpandable }) =>
        isExpandable ? "block" : "none"};
      content: "";
      position: absolute;
      top: 70%;
      width: 10%;
      height: 30px;
    }
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin: auto 0;

  @media ${media.tablet} {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
  }
`;
