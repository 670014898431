export const getFirstSpanVariantFromChildren = (
  children: React.ReactNode
): string | undefined => {
  const isChildrenArray = Array.isArray(children);
  const paragraphChildren = isChildrenArray
    ? [...children]
    : undefined;
  const spanChild =
    paragraphChildren && paragraphChildren.length
      ? paragraphChildren?.find((child) => child.props?.as === "span")
      : undefined;

  const variant = spanChild?.props.variant;

  return variant;
};
