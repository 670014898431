import styled from "styled-components";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";
import { GridBox } from "@pagepro-monorepo/ui/lib/components/styles/Grid";

export const Line = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  margin: 12px 0px 16px;
  grid-column: 1 / 5;
  display: none;
  @media ${media.mobile} {
    display: block;
  }
`;

export const Grid = styled(GridBox)`
  row-gap: 2.75rem;
  column-gap: 1.5rem;
  @media ${media.tablet} {
    row-gap: 0px;
    column-gap: 0px;
  }
`;
