// eslint-disable-next-line @typescript-eslint/no-var-requires
const { default: nextDynamic } = require("next/dynamic");

const isServer = !process.browser;

if (!isServer) {
  window.__NEXT_DYNAMIC_CALLS__ = window.__NEXT_DYNAMIC_CALLS__ || {
    calls: [],
    subscribers: [],
    add(dynamicResult) {
      this.calls.push(dynamicResult);

      this.subscribers.forEach((subscriber) =>
        subscriber([dynamicResult])
      );
    },
    subscribe(callback) {
      callback(this.calls);
      this.subscribers.push(callback);

      return () => {
        this.subscribers = this.subscribers.filter(
          (subscriber) => subscriber !== callback
        );
      };
    },
    has(dynamicResult) {
      return this.calls.includes(dynamicResult);
    }
  };
}

const NextDynamicProxy = (...args) => {
  const dynamicResult = nextDynamic(...args);

  if (isServer) {
    return dynamicResult;
  }

  const dynamicCalls = window.__NEXT_DYNAMIC_CALLS__;

  if (dynamicCalls.has(dynamicResult)) {
    return dynamicResult;
  }

  dynamicCalls.add(dynamicResult);

  return dynamicResult;
};

export default NextDynamicProxy;
