import { media } from "@pagepro-monorepo/ui";
import styled, { css } from "styled-components";

import { StyledResizableIconProps } from "./types";

export const Wrapper = styled.div<StyledResizableIconProps>`
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  div {
    display: flex;
  }

  svg {
    font-size: ${({ sizeMobile, sizeDesktop }) =>
      sizeMobile || sizeDesktop};
    @media ${media.mobile} {
      font-size: ${({ sizeTablet, sizeDesktop }) =>
        sizeTablet || sizeDesktop};
    }
    @media ${media.tablet} {
      font-size: ${({ sizeDesktop }) => sizeDesktop};
    }
    width: 1em;
    height: 1em;
    fill: ${({ fill }) => fill ?? "currentColor"};
  }
`;
