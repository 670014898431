export const variants = {
  visible: {
    transition: {
      staggerChildren: 0.05,
      delayChildren: 0.01,
      when: "beforeChildren",
      ease: "linear"
    }
  },
  hidden: {
    transition: {
      staggerChildren: 0.03,
      staggerDirection: -1,
      when: "afterChildren",
      ease: "linear"
    }
  }
};

export const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: {
    pathLength: 1,
    opacity: 1
  }
};
