import {
  StoryblokComponent,
  storyblokEditable
} from "@storyblok/react";
import React from "react";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";

import useToggle from "@hooks/useToggle";

import { FooterMenuProps } from "./types";
import * as Styled from "./styles";

const FooterMenu: React.FC<FooterMenuProps> = ({ blok }) => {
  const [isOpen, { toggle }] = useToggle();
  const { title, footer_menu_items: items } = blok;

  return (
    <Styled.Wrapper {...storyblokEditable(blok)} {...{ isOpen }}>
      <Styled.Toggler type="button" onClick={toggle}>
        <Typography variant="body2" as="span">
          {title}
        </Typography>
        <Styled.ToggleIcon />
      </Styled.Toggler>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Line />
      <Styled.ListWrapper>
        <Styled.List>
          {items.map((item) => (
            <li key={item._uid}>
              <StoryblokComponent blok={item} />
            </li>
          ))}
        </Styled.List>
      </Styled.ListWrapper>
    </Styled.Wrapper>
  );
};

export default FooterMenu;
