import styled, { css } from "styled-components";
import { rgba } from "polished";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";

import { StyledModalProps } from "./types";

export const Overlay = styled.button.attrs({
  type: "button"
})`
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  position: fixed;
  &::after {
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    background-color: ${({ theme }) =>
      rgba(theme.colors.primary, 0.5)};
    opacity: 0;
    transition: ${({ theme }) => theme.transition};
  }
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.25rem 1fr 1.25rem;
  grid-template-rows: 1.25rem 1fr 1.25rem;
  height: 100%;
  justify-content: center;
  transition: ${({ theme }) => theme.transition};
  opacity: 0;

  @media ${media.desktop} {
    grid-template-columns: 2rem 67.5rem 2rem;
    grid-template-rows: 2rem 1fr 2rem;
  }
`;

export const Content = styled.div`
  grid-column: 2 / -2;
  grid-row: 2 / -2;
  overflow: auto;
  z-index: 1;
  box-shadow: 0 0.25rem 3.125rem 0
    ${({ theme }) => rgba(theme.colors.black, 0.15)};
`;

export const Modal = styled.div<StyledModalProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  height: 100vh;
  padding: 2rem 0;
  visibility: ${({ $isVisible }) =>
    $isVisible ? "visible" : "hidden"};
  transition: ${({ theme }) => theme.transition};

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      ${Overlay}::after,
      ${ContentWrapper} {
        opacity: 1;
      }
    `}
`;
