import ClutchLogo from "@assets/svg/clutch-logo.svg";
import Star from "@assets/svg/star.svg";

import * as Styled from "./styles";
import { ClutchProps } from "./types";

const Clutch: React.FC<ClutchProps> = ({
  rate,
  reviewsAmount,
  link: { href, rel, target } = {}
}) => {
  const body = (
    <Styled.Container>
      <Styled.Badge>
        <ClutchLogo />
      </Styled.Badge>
      <Styled.Content>
        <Styled.Header>
          <Styled.Score>{rate}</Styled.Score>
          <Styled.Stars>
            <Star />
            <Star />
            <Star />
            <Star />
            <Star />
          </Styled.Stars>
        </Styled.Header>
        {reviewsAmount ? (
          <Styled.Description>
            Based on {reviewsAmount} Clutch reviews
          </Styled.Description>
        ) : null}
      </Styled.Content>
    </Styled.Container>
  );

  return href ? (
    <Styled.WrapperLink {...{ href, rel, target }}>
      {body}
    </Styled.WrapperLink>
  ) : (
    body
  );
};

export default Clutch;
