import media from "@pagepro-monorepo/ui/lib/theme/config/media";
import styled from "styled-components";

export const List = styled.ul<{ isScrolled: boolean }>`
  display: none;

  @media ${media.desktop} {
    position: absolute;
    top: 100%;
    left: 0px;
    width: 100%;
    height: 2.875rem;
    transition: ${({ theme }) => theme.transition};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    box-shadow: 0px 0.125rem 0.625rem 0px rgba(13, 47, 66, 0.1);
    background-color: ${({ theme, isScrolled }) =>
      isScrolled ? theme.colors.secondaryBackground : "transparent"};
    visibility: ${({ isScrolled }) =>
      isScrolled ? "visible" : "hidden"};
  }
`;

export const ListItem = styled.li<{
  isActive: boolean;
  isScrolled: boolean;
}>`
  transition: ${({ theme }) => theme.transition};
  opacity: ${({ isScrolled }) => (isScrolled ? 1 : 0)};
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.accent : theme.colors.primary};
  font-size: 0.875rem;
  font-weight: 500;

  :hover {
    color: ${({ theme }) => theme.colors.accent};
  }
`;
