import Logo from "./logo.svg";
import LogoSygnet from "./logo-sygnet.svg";
// Arrows
import IconArrowDown from "./arrow-down.svg";
import IconArrowRight from "./arrow-right.svg";
import IconArrowLeft from "./arrow-left.svg";
import IconChevronDown from "./chevron-down.svg";
// Socials
import IconInstagram from "./instagram.svg";
import IconFacebook from "./facebook.svg";
import IconTwitter from "./twitter.svg";
import IconLinkedin from "./linkedin.svg";
import IconYoutube from "./youtube.svg";
// Misc
import IconAttachment from "./attachment.svg";
import IconClose from "./close.svg";
import IconFilters from "./filters.svg";
import IconPlay from "./play.svg";
import IconTick from "./tick.svg";
// Illustrations
import IllustrationBialystok from "./bialystok.svg";
import IllustrationLondon from "./london.svg";
import IllustrationMessageSent from "./message-sent.svg";

export {
  Logo,
  LogoSygnet,
  IconArrowDown,
  IconArrowRight,
  IconArrowLeft,
  IconChevronDown,
  IconInstagram,
  IconFacebook,
  IconTwitter,
  IconLinkedin,
  IconYoutube,
  IconAttachment,
  IconClose,
  IconFilters,
  IconPlay,
  IconTick,
  IllustrationBialystok,
  IllustrationLondon,
  IllustrationMessageSent
};
