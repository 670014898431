import React from "react";
import { FlexBox } from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";

import { getImage } from "@utils/getImage";
import Image from "@components/atoms/Image";
import { tryToReplaceDomain } from "@utils/tryToReplaceDomain";
import ResizableIcon from "@components/atoms/ResizableIcon";

import * as Styled from "./styles";
import { FooterPartnershipsProps } from "./types";

const FooterPartnerships: React.FC<FooterPartnershipsProps> = ({
  title,
  assets
}) => (
  <FlexBox flexDirection="column" mb={["M", "XXS"]}>
    <Typography variant="body2" fontWeight="semibold" mb={[21, 0]}>
      {title}
    </Typography>
    <Styled.Line />
    <Styled.Grid
      as="ul"
      gridTemplateColumns={["repeat(2, 1fr)", "repeat(3, 3fr) 2fr"]}
    >
      {assets.map((img, index) => (
        <React.Fragment key={img.id}>
          <li>
            <FlexBox height={[35, 77]} alignItems="center">
              {img.content_type === "svg" ? (
                <ResizableIcon
                  image={tryToReplaceDomain(img.filename)}
                  sizeDesktop="48px"
                  sizeMobile="35px"
                  fill="none"
                />
              ) : (
                <Image
                  {...getImage(img)}
                  style={{
                    objectFit: "contain",
                    objectPosition: "left center"
                  }}
                  height={48}
                  width={48}
                  src={img.filename}
                  alt={img?.alt || "Partnership logo"}
                />
              )}
            </FlexBox>

            {img?.title && (
              <Typography variant="body6" mt={[22, 9]} opacity={0.5}>
                {img.title}
              </Typography>
            )}
          </li>
          {(index + 1) % 4 === 0 && <Styled.Line />}
        </React.Fragment>
      ))}
    </Styled.Grid>
  </FlexBox>
);

export default FooterPartnerships;
