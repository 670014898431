export const tryToReplaceDomain = (url: string): string => {
  try {
    const parsedUrl = new URL(url);

    parsedUrl.hostname = "s3.amazonaws.com";
    parsedUrl.pathname = `/a.storyblok.com${parsedUrl.pathname}`;

    return parsedUrl.toString();
  } catch {
    return url;
  }
};
