import styled from "styled-components";
import {
  color,
  ColorProps,
  layout,
  LayoutProps
} from "styled-system";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";
import shouldForwardProp from "@pagepro-monorepo/ui/lib/utils/shouldForwardProp";
import { FlexBox } from "@pagepro-monorepo/ui/lib/components/styles/Grid";

export const Wrapper = styled("div").withConfig({
  shouldForwardProp
})<ColorProps>`
  ${color};
  width: 100%;
  padding: ${({ theme }) => `${theme.space.S} 0`};
  @media ${media.mobile} {
    padding: ${({ theme }) => `${theme.space.L} 0 ${theme.space.S}`};
  }
  @media ${media.tablet} {
    padding: ${({ theme }) =>
      `${theme.space.XXL} 0 ${theme.space.L}`};
  }
`;

export const Header = styled(FlexBox).attrs({
  flexDirection: "column",
  justifyContent: "space-between",
  mb: ["S", "S", "L"]
})`
  gap: 1rem;

  @media ${media.mobile} {
    flex-direction: row;
    align-items: center;
  }
`;

export const ListItem = styled.li`
  border-top: 1px solid ${({ theme }) => theme.colors.secondary};
  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
  }
  @media ${media.mobile} {
    border-top: 0;
    &:last-of-type {
      border-bottom: 0;
    }
  }
`;

export const Media = styled.figure<LayoutProps>`
  ${layout};
  margin: 0;
  padding: 0;
  display: none;
  @media ${media.mobile} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
