import styled from "styled-components";
import { GridBox as Grid } from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import { media } from "@pagepro-monorepo/ui";

import { IconArrowRight } from "@assets/svg";
import Link from "@components/atoms/Link";

export const GridBox = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  column-gap: 3rem;
  row-gap: 2rem;
  padding-top: 0rem;
  padding-bottom: 3.175rem;
  @media ${media.desktopLg} {
    padding-top: 4rem;
    column-gap: 3rem;
    row-gap: 4.75rem;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 25.625rem;
  margin-top: 3rem;
`;

export const ServiceCardWrapper = styled.ul`
  display: flex;
  gap: 1.875rem;
  margin-top: 3rem;
  a {
    width: 11.875rem;
    min-height: 15.5rem;
    padding: 1.25rem;
    background-image: none;
    border: 1px solid #dadada;
    background-image: linear-gradient(
      to top,
      ${({ theme }) => theme.colors.white} 50%,
      ${({ theme }) => theme.colors.accent} 50%
    );
    span {
      line-height: 125%;
      font-size: 1rem;
      font-weight: 500;
    }
  }
`;

export const BottomLinkWrapper = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 11.875rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  transition: ${({ theme }) => theme.transition};
  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.accent};
    color: ${({ theme }) => theme.colors.accent};
  }
`;

export const Arrow = styled(IconArrowRight)`
  height: 1em;
  width: 1.3em;
  fill: currentColor;
`;
