import React from "react";
import { Inner } from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import { StoryblokComponent } from "@storyblok/react";

import * as Styled from "./styles";
import { HeaderContentProps } from "./types";
import { getFullHref } from "./utils";

const HeaderContent: React.FC<HeaderContentProps> = ({
  item: {
    linksTitle,
    links,
    linksBottomLabel,
    linksBottomUrl,
    cardsTitle,
    cards,
    cardsBottomLabel,
    cardsBottomUrl
  }
}) => (
  <Inner>
    <Styled.GridBox>
      <div>
        {linksTitle?.map((title) => (
          <StoryblokComponent key={title._uid} blok={title} />
        ))}
        <Styled.LinksWrapper>
          {links?.map((link) => (
            <StoryblokComponent key={link._uid} blok={link} />
          ))}
        </Styled.LinksWrapper>
      </div>
      <div>
        {cardsTitle?.map((title) => (
          <StoryblokComponent key={title._uid} blok={title} />
        ))}
        <Styled.ServiceCardWrapper>
          {cards?.map((card) => (
            <li key={card._uid}>
              <StoryblokComponent blok={card} />
            </li>
          ))}
        </Styled.ServiceCardWrapper>
      </div>
      <div>
        <Styled.BottomLinkWrapper href={getFullHref(linksBottomUrl)}>
          {linksBottomLabel?.map((label) => (
            <StoryblokComponent key={label._uid} blok={label} />
          ))}
          <Styled.Arrow />
        </Styled.BottomLinkWrapper>
      </div>
      <div>
        <Styled.BottomLinkWrapper href={getFullHref(cardsBottomUrl)}>
          {cardsBottomLabel?.map((label) => (
            <StoryblokComponent key={label._uid} blok={label} />
          ))}
          <Styled.Arrow />
        </Styled.BottomLinkWrapper>
      </div>
    </Styled.GridBox>
  </Inner>
);

export default HeaderContent;
