import styled, { css } from "styled-components";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";

import LinkComponent from "@components/atoms/Link";
import ButtonLinkComponent from "@components/atoms/ButtonLink";

import { StyledHeaderLinkProps } from "./types";

const linkCommonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font: inherit;
  padding: 0.375rem 0.75rem;
  color: inherit;
`;

export const Link = styled(LinkComponent)<StyledHeaderLinkProps>`
  ${linkCommonStyles};
  @media ${media.tablet} {
    transition: ${({ theme }) => theme.transition};
    transition-property: color;
    &:hover {
      color: ${({ theme, $isActive }) =>
        $isActive ? "inherit" : theme.colors.accent};
    }
  }
  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      color: ${theme.colors.white};
      background-color: ${theme.colors.primary};
      @media ${media.tablet} {
        position: relative;
        color: inherit;
        background-color: transparent;

        &::after {
          z-index: -1;
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: calc(100% - (0.375rem * 2));
          height: calc(50% - 0.375rem);
          background-color: ${theme.colors.accent};
          transform: translateX(-50%);
        }
      }
    `}
`;

export const ButtonLink = styled(ButtonLinkComponent)`
  ${linkCommonStyles};
  background-color: transparent;
  border: 0;
`;
