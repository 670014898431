import { media } from "@pagepro-monorepo/ui";
import styled from "styled-components";
import { compose, variant } from "styled-system";
import { buttonVariants } from "@pagepro-monorepo/ui/lib/components/atoms/Button/consts";

import ButtonLink from "@components/atoms/ButtonLink";

import { StickyButtonLinkProps } from "./types";

export const StickyButtonLink = styled(
  ButtonLink
)<StickyButtonLinkProps>`
  position: fixed;
  left: calc(100vw - 11.0625rem);
  bottom: 1rem;
  min-height: unset;
  height: 2.25rem;
  width: 9.0625rem;
  z-index: 2;
  gap: 0.75rem;
  padding: 0.565rem;
  font-size: 0.875rem;
  font-weight: 500;
  visibility: ${({ isScrolled }) =>
    isScrolled ? "visible" : "hidden"};
  opacity: ${({ isScrolled }) => (isScrolled ? 1 : 0)};
  ${compose(
    variant({
      scale: "mode",
      prop: "$mode",
      variants: buttonVariants
    })
  )}
  div {
    display: flex;
  }

  svg {
    height: 1.125rem;
    width: 1.125rem;
    fill: currentColor;
  }

  @media ${media.maxMobile} {
    width: 2.25rem;
    height: 2.25rem;
    left: calc(100vw - 4.25rem);
    span {
      display: none;
    }
  }
`;
