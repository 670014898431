import dynamic from "next/dynamic";

import HeaderNavigation from "@components/organisms/Header/partials/HeaderNavigation";
import HeaderLink from "@components/organisms/Header/partials/HeaderLink";
import FooterLink from "@components/organisms/Footer/partials/FooterLink";
import FooterMenu from "@components/organisms/Footer/partials/FooterMenu";
import SocialMediaLink from "@components/atoms/SocialMediaLink";

export const components = {
  grid: dynamic(() => import("@components/storyblok/Grid")),
  teaser: dynamic(() => import("@components/storyblok/Teaser")),
  page: dynamic(() => import("@components/storyblok/pages/Page")),
  case_study_page: dynamic(
    () => import("@components/storyblok/pages/CaseStudyPage")
  ),
  header_menu: HeaderNavigation,
  menu_link: HeaderLink,
  section_heading: dynamic(
    () => import("@components/storyblok/SectionHeadingStoryblok")
  ),
  section: dynamic(
    () => import("@components/storyblok/SectionStoryblok")
  ),
  config: () => null,
  footer_link: FooterLink,
  footer_menu: FooterMenu,
  social_media_link: SocialMediaLink,
  button: dynamic(() => import("@components/atoms/Button")),
  rich_text: dynamic(() => import("@components/storyblok/RichText")),
  logoCarousel: dynamic(
    () => import("@components/storyblok/LogoCarouselStoryblok")
  ),
  caseStudiesCarousel: dynamic(
    () => import("@components/storyblok/CaseStudiesCarouselStoryblok")
  ),
  cardsWithIcon: dynamic(
    () => import("@components/storyblok/CardsWithIconStoryblok")
  ),
  video_player: dynamic(
    () => import("@components/storyblok/VideoPlayerStoryblok")
  ),
  numbers_section: dynamic(
    () => import("@components/storyblok/NumbersSectionStoryblok")
  ),
  image_grid: dynamic(
    () => import("@components/storyblok/ImageGrid")
  ),
  case_study_hero: dynamic(
    () => import("@components/storyblok/CaseStudyHeroStoryblok")
  ),
  testimonials: dynamic(
    () => import("@components/storyblok/TestimonialsStoryblok")
  ),
  testimonial: dynamic(
    () => import("@components/storyblok/TestimonialStoryblok")
  ),
  banner: dynamic(() => import("@components/storyblok/Banner")),
  spacer: dynamic(
    () => import("@components/storyblok/SpacerStoryblok")
  ),
  inner: dynamic(() => import("@components/storyblok/Inner")),
  timeline: dynamic(
    () => import("@components/storyblok/TimelineStoryblok")
  ),
  context_pictures: dynamic(
    () => import("@components/storyblok/ContextPictures")
  ),
  infoTable: dynamic(
    () => import("@components/storyblok/InfoTableStoryblok")
  ),
  deliveryDetails: dynamic(
    () => import("@components/storyblok/DeliveryDetailsStoryblok")
  ),
  line: dynamic(() => import("@components/storyblok/LineStoryblok")),
  serviceTile: dynamic(
    () => import("@components/storyblok/ServiceTileStoryblok")
  ),
  servicesList: dynamic(
    () => import("@components/storyblok/ServicesListStoryblok")
  ),
  featureLinks: dynamic(
    () => import("@components/storyblok/FeatureLinksStoryblok")
  ),
  technologyCards: dynamic(
    () => import("@components/storyblok/TechnologyCardsStoryblok")
  ),
  image: dynamic(
    () => import("@components/storyblok/ImageStoryblok")
  ),
  allCaseStudies: dynamic(
    () => import("@components/storyblok/AllCaseStudies")
  ),
  anchorLink: dynamic(
    () => import("@components/storyblok/AnchorLinkStoryblok")
  ),
  anchorLinksList: dynamic(
    () => import("@components/storyblok/AnchorLinkListStoryblok")
  ),
  iconBox: dynamic(
    () => import("@components/storyblok/IconBoxStoryblok")
  ),
  imageComparison: dynamic(
    () => import("@components/storyblok/ImageComparisonStoryblok")
  ),
  imageComparisonSlider: dynamic(
    () =>
      import("@components/storyblok/ImageComparisonSliderStoryblok")
  ),
  calendlyWidget: dynamic(
    () => import("@components/storyblok/CalendlyWidgetStoryblok")
  ),
  centeredIconBox: dynamic(
    () => import("@components/storyblok/CenteredIconBoxStoryblok")
  ),
  clutchWidget: dynamic(
    () => import("@components/storyblok/ClutchWidgetStoryblok")
  ),
  tabs: dynamic(() => import("@components/storyblok/TabsStoryblok")),
  caseStudyCard: dynamic(
    () => import("@components/storyblok/CaseStudyCardStoryblok")
  ),
  prosAndCons: dynamic(
    () => import("@components/storyblok/ProsAndConsStoryblok")
  ),
  imagesWithCaptions: dynamic(
    () => import("@components/storyblok/ImagesWithCaptionsStoryblok")
  ),
  landingPageHero: dynamic(
    () => import("@components/storyblok/LandingPageHeroStoryblok")
  ),
  marqueeBackground: dynamic(
    () => import("@components/storyblok/MarqueeBackgroundStoryblok")
  ),
  contactForm: dynamic(
    () => import("@components/storyblok/ContactFormStoryblok")
  ),
  danteAiWidget: dynamic(
    () => import("@components/storyblok/DanteAiWidgetStoryblok")
  ),
  downloadForm: dynamic(
    () => import("@components/storyblok/DownloadFormStoryblok")
  ),
  horizontalContactForm: dynamic(
    () =>
      import("@components/storyblok/HorizontalContactFormStoryblok")
  ),
  numberedList: dynamic(
    () => import("@components/storyblok/NumberedListStoryblok")
  ),
  postCard: dynamic(
    () => import("@components/storyblok/PostCardStoryblok")
  ),
  initiativeBox: dynamic(
    () => import("@components/storyblok/InitiativeBox")
  ),
  infoCtaBox: dynamic(
    () => import("@components/storyblok/InfoCtaBoxStoryblok")
  ),
  infoCtaBoxList: dynamic(
    () => import("@components/storyblok/InfoCtaBoxListStoryblok")
  ),
  resizableIcon: dynamic(
    () => import("@components/storyblok/ResizableIconStoryblok")
  ),
  hero: dynamic(() => import("@components/storyblok/HeroStoryblok")),
  eventInfoBox: dynamic(
    () => import("@components/storyblok/EventInfoBoxStoryblok")
  ),
  serviceCard: dynamic(
    () => import("@components/storyblok/ServiceCardStoryblok")
  ),
  newsletterForm: dynamic(
    () => import("@components/storyblok/NewsletterFormStoryblok")
  ),
  branches: dynamic(
    () => import("@components/storyblok/BranchesStoryblok")
  ),
  table: dynamic(
    () => import("@components/storyblok/TableStoryblok")
  ),
  orderedList: dynamic(
    () => import("@components/storyblok/OrderedListStoryblok")
  ),
  imagesMatrix: dynamic(
    () => import("@components/storyblok/ImagesMatrixStoryblok")
  ),
  textCard: dynamic(
    () => import("@components/storyblok/TextCardStoryblok")
  ),
  autoplayVideo: dynamic(
    () => import("@components/storyblok/AutoplayVideoStoryblok")
  ),
  extendedTable: dynamic(
    () => import("@components/storyblok/ExtendedTableStoryblok")
  ),
  caseStudyCardCarousel: dynamic(
    () =>
      import("@components/storyblok/CaseStudyCardCarouselStoryblok")
  ),
  technologyTile: dynamic(
    () => import("@components/storyblok/TechnologyTileStoryblok")
  )
};
