import styled, { css } from "styled-components";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";

import { StyledMainLayoutProps } from "./types";

export const Wrapper = styled.div<StyledMainLayoutProps>`
  padding-top: ${({ theme }) => theme.sizes.header};
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  ${({ withPadding }) =>
    !withPadding &&
    css`
      @media ${media.mobile} {
        padding-top: 0;
      }
    `}
`;

export const Main = styled.main`
  min-height: calc(100vh - ${({ theme }) => theme.sizes.header});
`;
