import { isSafari } from "./userAgent";

export const enableScrollOnBody = (): void => {
  const { body } = document;

  body.classList.remove("has-overflow-hidden");

  if (isSafari()) {
    body.style.paddingRight = "0px";
  }
};
