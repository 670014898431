import { useContext, useEffect, useRef } from "react";

import { HeaderModeContextValue } from "./types";

import { HeaderModeContext } from ".";

export const useHeaderModeContext = (): HeaderModeContextValue =>
  useContext(HeaderModeContext);

const useHeaderMode = (transparent: boolean): void => {
  const { isHeaderTransparent, setIsHeaderTransparent } =
    useHeaderModeContext();
  const prevIsHeaderTransparent = useRef(isHeaderTransparent);

  useEffect(() => {
    const prevTransparent = prevIsHeaderTransparent.current;

    setIsHeaderTransparent(transparent);

    return () => {
      setIsHeaderTransparent(prevTransparent);
    };
  }, [setIsHeaderTransparent, transparent]);
};

export default useHeaderMode;
