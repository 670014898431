export function pullAt<T>(arr: T[], ...indices: number[]): T[] {
  const removed: T[] = [];
  let i = 0;

  while (i < arr.length) {
    if (indices.indexOf(i) !== -1) {
      removed.push(arr[i]);
      arr.splice(i, 1);
    } else {
      i += 1;
    }
  }

  return removed;
}
