import { ImageLoader } from "next/image";

export const loader: ImageLoader = ({ src, width, quality }) => {
  if (src.startsWith("https://a.storyblok.com")) {
    if (!quality) {
      return `${src}/m/${width}x0`;
    }

    return `${src}/m/${width}x0/filters:quality(${quality})`;
  }

  const [, widthAsString] = src.split("$");
  const widthFromUrl = Number.parseInt(widthAsString, 10);

  if (Number.isNaN(widthFromUrl) || width >= widthFromUrl) {
    return src;
  }

  return src.replace(`$${widthAsString}$`, `$${width}$`);
};
