import { storyblokInit, apiPlugin } from "@storyblok/react";

import { components } from "./consts";

export const StoryblokInit = () => {
  storyblokInit({
    accessToken: process.env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN,
    apiOptions: {
      cache: {
        clear: "auto",
        type: "memory"
      }
    },
    use: [apiPlugin],
    components,
    bridge: true
  });
};
