import { useEffect } from "react";

const useResize = (): void => {
  const resizeClass = "is-resize";

  useEffect(() => {
    let resizeTimer: ReturnType<typeof setTimeout>;

    const handleWindowResize = () => {
      document.body.classList.add(resizeClass);

      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        document.body.classList.remove(resizeClass);
      }, 500);
    };

    window.addEventListener("resize", handleWindowResize, {
      passive: true
    });

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
};

export default useResize;
