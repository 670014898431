import React from "react";
import { m } from "framer-motion";
import { useTheme } from "styled-components";

import { variants, draw } from "./consts";
import { HeaderLogoProps } from "./types";

const HeaderLogo: React.FC<HeaderLogoProps> = ({ isOpen }) => {
  const theme = useTheme();

  return (
    <m.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 106 40"
      height="40"
      width="106"
      initial="visible"
      animate={isOpen ? "hidden" : "visible"}
      {...{ variants }}
    >
      <m.path
        variants={draw}
        d="M36.24,21.19c.51-.53,.75-1.26,.75-2.14s-.24-1.62-.75-2.14c-.51-.53-1.17-.81-1.95-.81-.72,0-1.37,.28-1.92,.84s-.82,1.26-.82,2.14,.27,1.58,.82,2.14,1.2,.81,1.92,.81c.79-.04,1.44-.32,1.95-.84Zm1.82-5.9c.96,.98,1.41,2.25,1.41,3.76s-.48,2.74-1.41,3.72-2.06,1.47-3.33,1.47-2.37-.39-3.19-1.16v5.58h-2.54V14.1h2.54v.84c.82-.77,1.89-1.16,3.19-1.16,1.27,.04,2.37,.53,3.33,1.51Z"
      />
      <m.path
        variants={draw}
        d="M46.46,21.54c.51-.42,.75-.95,.75-1.58v-.11h-2.81c-.41,0-.72,.11-.96,.32-.24,.21-.38,.49-.38,.81,0,.39,.14,.67,.45,.88s.69,.32,1.2,.32c.65,0,1.27-.21,1.75-.63h0Zm2.06-6.79c.75,.67,1.13,1.58,1.13,2.74v6.46h-2.44v-.81c-.75,.74-1.71,1.12-2.95,1.12-1.1,0-1.99-.28-2.68-.88-.69-.6-.99-1.37-.99-2.32s.34-1.72,1.03-2.32c.69-.56,1.61-.88,2.81-.88h2.78v-.18c0-.49-.14-.91-.45-1.19-.31-.32-.69-.46-1.2-.46-1.13,0-1.82,.49-2.02,1.44l-2.44-.49c.17-.95,.65-1.72,1.47-2.32,.79-.6,1.75-.88,2.85-.88,1.3,0,2.33,.32,3.09,.95v.02Z"
      />
      <m.path
        variants={draw}
        d="M58.25,21.15c.55-.56,.82-1.26,.82-2.14s-.27-1.58-.82-2.14-1.2-.84-1.92-.84c-.79,0-1.44,.28-1.95,.81-.51,.53-.75,1.26-.75,2.14s.24,1.62,.75,2.14c.51,.53,1.17,.81,1.95,.81,.72,.07,1.37-.21,1.92-.77h0Zm3.36,3.05c0,1.47-.51,2.63-1.51,3.48-.99,.84-2.3,1.23-3.91,1.23-2.23,0-3.81-.74-4.7-2.18l2.06-1.79c.41,.53,.79,.88,1.17,1.09,.38,.21,.86,.32,1.47,.32,.86,0,1.54-.21,2.09-.67,.55-.42,.79-1.05,.79-1.9v-.7c-.82,.77-1.89,1.16-3.19,1.16s-2.37-.49-3.33-1.47c-.93-.98-1.41-2.25-1.41-3.72s.48-2.74,1.41-3.76c.96-.98,2.06-1.51,3.33-1.51s2.37,.39,3.19,1.16v-.84h2.54v10.11h0Z"
      />
      <m.path
        variants={draw}
        d="M67.07,16.55c-.45,.32-.75,.77-.89,1.33h4.66c-.17-.6-.45-1.05-.86-1.37-.41-.32-.89-.46-1.44-.46-.51,0-1.03,.18-1.47,.49h0Zm6.38,3.21h-7.34c.14,.7,.41,1.26,.86,1.69,.45,.42,.96,.63,1.54,.63,1.1,0,1.89-.42,2.4-1.3l2.26,.49c-.41,1.02-1.03,1.76-1.85,2.28-.82,.53-1.75,.77-2.85,.77-1.41,0-2.57-.49-3.53-1.47s-1.44-2.21-1.44-3.76,.48-2.77,1.44-3.76c.96-.98,2.13-1.47,3.53-1.47,1.3,0,2.44,.46,3.4,1.4,.96,.95,1.44,2.14,1.51,3.62v.88h.07Z"
      />
      <m.path
        variants={draw}
        d="M82.6,21.19c.51-.53,.75-1.26,.75-2.14s-.24-1.62-.75-2.14c-.51-.53-1.17-.81-1.95-.81-.72,0-1.37,.28-1.92,.84-.55,.56-.82,1.26-.82,2.14s.27,1.58,.82,2.14,1.2,.81,1.92,.81c.79-.04,1.44-.32,1.95-.84Zm1.82-5.9c.96,.98,1.41,2.25,1.41,3.76s-.48,2.74-1.41,3.72-2.06,1.47-3.33,1.47-2.37-.39-3.19-1.16v5.58h-2.54V14.1h2.54v.84c.82-.77,1.89-1.16,3.19-1.16,1.27,.04,2.37,.53,3.33,1.51Z"
      />
      <m.path
        variants={draw}
        d="M94.44,14.02l-.14,2.53h-.55c-2.3,0-3.43,1.4-3.43,4.25v3.2h-2.54V14.1h2.54v1.79c.89-1.3,2.02-1.93,3.46-1.93,.27,0,.51,.04,.65,.07h.01Z"
      />
      <m.path
        variants={draw}
        d="M102.32,21.15c.55-.56,.82-1.26,.82-2.14s-.27-1.58-.82-2.14-1.2-.81-1.89-.81-1.37,.28-1.96,.81c-.55,.53-.82,1.26-.82,2.14s.27,1.58,.82,2.14c.55,.53,1.2,.81,1.96,.81,.72,.04,1.34-.25,1.89-.81Zm1.78-5.83c.99,.98,1.51,2.21,1.51,3.72s-.51,2.77-1.51,3.72c-.99,.98-2.23,1.44-3.67,1.44s-2.71-.49-3.74-1.44c-1.03-.98-1.51-2.21-1.51-3.72s.51-2.77,1.51-3.72c1.03-.98,2.26-1.44,3.74-1.44,1.44-.04,2.67,.46,3.67,1.44Z"
      />
      <path
        d="M14.1,21.74l-7.02,3.5V6.83l7.02,3.5v11.41Zm0,4.33l-7.02,3.5v-1.88l7.02-3.5v1.88Zm2.09,.16V11.36l1.63,.81v10.04c1.16,0,2.09-1.01,2.09-2.24v-7.94c0-.76-.42-1.46-1.07-1.79L7.07,4.34v-1.87l9.12,4.56v-1.06c0-.87-.47-1.66-1.21-2.02L7.6,.29c-1.23-.61-2.63,.34-2.63,1.79V37.69c0,1.23,.95,2.24,2.09,2.24v-7.87l8.04-4c.66-.34,1.07-1.03,1.07-1.82h.01Z"
        fill={theme.colors.brand}
      />
      <path
        d="M2.49,3.83c-1.37,0-2.49,1.02-2.49,2.27v27.83c0,1.24,1.12,2.27,2.49,2.27V3.83Z"
        fill={theme.colors.brand}
      />
    </m.svg>
  );
};

export default HeaderLogo;
