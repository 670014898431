import Button from "@pagepro-monorepo/ui/lib/components/atoms/Button";
import React from "react";
import { useRouter } from "next/router";
import { storyblokEditable } from "@storyblok/react";

import ButtonLink from "@components/atoms/ButtonLink";
import { removeTrailingSlash } from "@utils/removeTrailingSlash";
import { removeLeadingSlash } from "@utils/removeLeadingSlash";

import * as Styled from "./styles";
import { HeaderLinkProps } from "./types";

const HeaderLink: React.FC<HeaderLinkProps> = ({
  blok,
  navLinks,
  onClick
}) => {
  const {
    isHighlighted,
    link: {
      url,
      cached_url: cachedUrl,
      rel,
      target,
      story: { full_slug: fullSlug = "" } = {}
    } = {},
    label
  } = blok;

  const { asPath } = useRouter();
  const cleanCurrentPath = removeLeadingSlash(asPath).replaceAll(
    "?",
    "/"
  );
  const paths = removeLeadingSlash(asPath)
    .replaceAll("?", "/")
    .split("/");
  const newestUrl = fullSlug || cachedUrl;
  const href = url || newestUrl;
  const path = removeTrailingSlash(href);
  const isNested = paths.some(
    (possiblePath) => possiblePath === path
  );
  const hasIndividualLink = navLinks?.some((link) => {
    if (link === cleanCurrentPath) {
      return link.split("/").includes(path);
    }

    return false;
  });
  const $isActive =
    cleanCurrentPath === path || (isNested && !hasIndividualLink);

  const linkProps = {
    onClick,
    href,
    rel,
    target,
    ...storyblokEditable(blok)
  };

  const linkComponent = isHighlighted ? (
    <ButtonLink $mode="primarySimple" size="small" {...linkProps}>
      {label}
    </ButtonLink>
  ) : (
    <Styled.Link
      {...{
        $isActive,
        ...linkProps
      }}
    >
      {label}
    </Styled.Link>
  );

  const buttonComponent = isHighlighted ? (
    <Button
      $mode="primarySimple"
      size="small"
      {...storyblokEditable(blok)}
    >
      {label}
    </Button>
  ) : (
    <Styled.ButtonLink type="button" {...storyblokEditable(blok)}>
      {label}
    </Styled.ButtonLink>
  );

  return url || cachedUrl ? linkComponent : buttonComponent;
};

export default HeaderLink;
