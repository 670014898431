import React, { useEffect, useLayoutEffect, useState } from "react";
import Hamburger from "@pagepro-monorepo/ui/lib/components/atoms/Hamburger";

import useToggle from "@hooks/useToggle";
import { disableScrollOnBody } from "@utils/disableScrollOnBody";
import { enableScrollOnBody } from "@utils/enableScrollOnBody";

import * as Styled from "./styles";
import { HeaderProps } from "./types";
import HeaderNavigation from "./partials/HeaderNavigation";
import HeaderLogo from "./partials/HeaderLogo";
import HeaderSubmenu from "./partials/HeaderSubmenu";

const Header: React.FC<HeaderProps> = ({
  isLight,
  items,
  submenu
}) => {
  const [isOpen, { toggle }] = useToggle();
  const [isScrolled, setIsScrolled] = useState(false);

  useLayoutEffect(() => {
    const onScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    onScroll();

    window.addEventListener("scroll", onScroll, {
      passive: true
    });

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("is-menu-opened");
      disableScrollOnBody();
    } else {
      document.body.classList.remove("is-menu-opened");
      enableScrollOnBody();
    }

    return () => {
      document.body.classList.remove("is-menu-opened");
      enableScrollOnBody();
    };
  }, [isOpen]);

  return (
    <Styled.Wrapper {...{ isLight, isScrolled }}>
      <Styled.Inner>
        <Styled.LogoLink href="/" aria-label="Pagepro">
          <Styled.LongLogo>
            <HeaderLogo {...{ isOpen }} />
          </Styled.LongLogo>
          <Styled.Sygnet />
        </Styled.LogoLink>
        <Styled.ControlsWrapper>
          <Styled.Hamburger>
            <Hamburger {...{ isOpen }} onClick={toggle} />
          </Styled.Hamburger>
          <Styled.Navigation
            itemsCount={items?.length || 0}
            {...{ isOpen }}
          >
            <HeaderNavigation {...{ isOpen, items, setIsScrolled }} />
          </Styled.Navigation>
        </Styled.ControlsWrapper>
      </Styled.Inner>
      {!!submenu?.length && (
        <HeaderSubmenu {...{ submenu, isScrolled }} />
      )}
    </Styled.Wrapper>
  );
};

export default Header;
