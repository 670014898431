import { useEffect, useState } from "react";

import { getHash } from "@utils/getHash";

let hashChangeHandlerRegistered = false;
const callbacksRegistry: (() => void)[] = [];
const hashChangeHandler = (): void => {
  callbacksRegistry.forEach((callback) => {
    callback();
  });
};

const useHash = () => {
  const windowHash = getHash();
  const [hash, setHash] = useState<string>();

  // handle rerender hash change
  useEffect(() => {
    setHash(windowHash);
  }, [windowHash]);

  // handle manual window hash assigment
  useEffect(() => {
    if (!hashChangeHandlerRegistered) {
      hashChangeHandlerRegistered = true;
      window.addEventListener("hashchange", hashChangeHandler);
    }

    const callback = () => {
      setHash(getHash());
    };

    callbacksRegistry.push(callback);
    callback();

    return () => {
      const callbackIndex = callbacksRegistry.indexOf(callback);

      if (callbackIndex < 0) {
        return;
      }

      callbacksRegistry.splice(callbackIndex, 1);
    };
  }, []);

  return hash;
};

export default useHash;
