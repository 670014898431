import { MessageFormatElement } from "react-intl";

import en from "@locale/compiled-locales/en.json";

export const BASE_PROPS_PAGE_PROPS_KEY = "BASE_PROPS";

export const i18n = {
  locales: ["en"],
  defaultLocale: "en"
} as const;

export type SupportedLocales = (typeof i18n.locales)[number];

export const LOCALES: Record<
  SupportedLocales,
  Record<string, MessageFormatElement[]>
> = {
  en
};
