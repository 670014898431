import React, { useEffect } from "react";
import { StoryblokComponent } from "@storyblok/react";
import { useRouter } from "next/router";

import { disableScrollOnBody } from "@utils/disableScrollOnBody";
import { enableScrollOnBody } from "@utils/enableScrollOnBody";

import HeaderContent from "../HeaderContent";

import { HeaderNavigationProps } from "./types";
import * as Styled from "./styles";

const HeaderNavigation: React.FC<HeaderNavigationProps> = ({
  items,
  setIsScrolled
}) => {
  const { asPath, events } = useRouter();

  const navLinks = items
    ?.filter(({ link: { url } = {} }) => !url)
    ?.map(
      ({
        link: {
          cached_url: cachedUrl,
          story: { full_slug: fullSlug = "" } = {}
        } = {}
      }) => {
        const newestUrl = fullSlug || cachedUrl;

        return newestUrl;
      }
    );

  useEffect(() => {
    const routeChangeHandler = (): void => {
      enableScrollOnBody();
    };

    events.on("routeChangeComplete", routeChangeHandler);
    events.on("hashChangeComplete", routeChangeHandler);

    return (): void => {
      events.off("routeChangeComplete", routeChangeHandler);
      events.off("hashChangeComplete", routeChangeHandler);
    };
  }, [events]);

  return items?.length ? (
    <Styled.List key={asPath}>
      {items.map((item, index) => (
        <Styled.ListItem
          {...{ index }}
          key={item._uid}
          ml={item.isHighlighted ? [0, "1rem", "1rem"] : undefined}
          mt={item.isHighlighted ? ["0.5rem", 0, 0] : undefined}
          isExpandable={item?.isExpandable}
          onMouseEnter={() => {
            if (item.isExpandable && setIsScrolled) {
              disableScrollOnBody();
            }
          }}
          onMouseOver={() => {
            if (item.isExpandable && setIsScrolled) {
              setIsScrolled(true);
            }
          }}
          onMouseLeave={() => {
            if (item.isExpandable && setIsScrolled) {
              enableScrollOnBody();
              setIsScrolled(window.scrollY > 0);
            }
          }}
        >
          <StoryblokComponent blok={item} {...{ navLinks }} />
          {item?.isExpandable && (
            <>
              <Styled.ToggleIcon />
              <Styled.ExpandContentWrapper>
                <HeaderContent {...{ item }} />
              </Styled.ExpandContentWrapper>
            </>
          )}
        </Styled.ListItem>
      ))}
    </Styled.List>
  ) : null;
};

export default HeaderNavigation;
