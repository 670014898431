import media from "@pagepro-monorepo/ui/lib/theme/config/media";
import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import reset from "styled-reset";

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${reset}

  * {
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
  }

  html {
    scrollbar-gutter: stable;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.body};
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.white};
    width: 100%;
  }

  body.is-fixed:not(.is-menu-opened) {
    position: fixed;
  }

  body.is-fixed.is-menu-opened {
    position: fixed;
    @media ${media.mobile} {
      position: static;
    }
  }

  body.has-overflow-hidden {
    overflow: hidden;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    color: inherit;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }

  strong,
  b {
    font-weight: bold;
  }

  ::selection {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.accent};
  }

  .is-resize *,
  .is-resize *::before,
  .is-resize *::after {
    transition: initial !important;
  }

  .is-smooth {
    scroll-behavior: smooth;
  }

  .transition-hidden {
    opacity: 0 !important;
    max-height: 0 !important;
    transition: ${({ theme }) => theme.transition};
  }

  .transition-visible {
    opacity: 1 !important;
    max-height: unset !important;
    transition: ${({ theme }) => theme.transition};
  }
`;
