import React, { PropsWithChildren } from "react";
import clsx from "clsx";
import { ButtonLink as UIButtonLink } from "@pagepro-monorepo/ui/lib/components/atoms/Button";
import { ButtonLinkProps as UIButtonLinkProps } from "@pagepro-monorepo/ui/lib/components/atoms/Button/types";

import { useContactPageUrlsContext } from "@contexts/ContactPageUrlsContext";
import { useCareerPageUrlsContext } from "@contexts/CareerPageUrlsContext";
import { useCaseStudyPageUrlsContext } from "@contexts/CaseStudyPageUrlsContext";

import { DEFAULT_FORM_ID } from "./consts";

const ButtonLink = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<UIButtonLinkProps>
>(({ className = "", ...props }, ref) => {
  const { href } = props;
  const contactPageUrls = useContactPageUrlsContext();
  const careerPageUrls = useCareerPageUrlsContext();
  const caseStudyPageUrls = useCaseStudyPageUrlsContext();

  const { pathname } = new URL(href || "/", "https://fake.domain");
  const isContactPageUrl = contactPageUrls?.some(
    (item) => item === pathname
  );

  const isCareerPageUrl = careerPageUrls?.some(
    (item) => item === pathname
  );

  const isCaseStudyPageUrl = caseStudyPageUrls?.some(
    (item) => item === pathname
  );

  const isEmailUrl = href?.startsWith("mailto:");
  const isSectionUrl = href?.startsWith(DEFAULT_FORM_ID);

  return href ? (
    <UIButtonLink
      className={clsx(className, {
        "ga-career-cta": isCareerPageUrl,
        "ga-casestudy-cta": isCaseStudyPageUrl,
        "ga-cta": isContactPageUrl,
        "ga-email-cta": isEmailUrl,
        "ga-scroll-to-form-cta": isSectionUrl
      })}
      {...{ ...props, ref }}
    />
  ) : null;
});

export default ButtonLink;
