import { storyblokEditable } from "@storyblok/react";
import React from "react";
import { ReactSVG } from "react-svg";

import { tryToReplaceDomain } from "@utils/tryToReplaceDomain";
import LazyHydrate from "@components/utils/LazyHydrate";

import { SocialMediaLinkProps } from "./types";
import * as Styled from "./styles";

const SocialMediaLink: React.FC<SocialMediaLinkProps> = ({
  blok
}) => {
  const {
    icon: { filename },
    link: { url }
  } = blok;

  return (
    <Styled.Link
      aria-label="Social Media Link"
      href={url}
      rel="noreferrer noopener nofollow"
      {...storyblokEditable(blok)}
    >
      <LazyHydrate whenVisible>
        <ReactSVG src={tryToReplaceDomain(filename)} wrapper="span" />
      </LazyHydrate>
    </Styled.Link>
  );
};

export default SocialMediaLink;
