export const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();

  return (
    ua.includes("safari") &&
    !ua.includes("chrome") && // Exclude Chrome
    !ua.includes("android") && // Exclude Android (Android WebView)
    !ua.includes("crios") && // Exclude Chrome on iOS
    !ua.includes("fxios") // Exclude Firefox on iOS
  );
};
