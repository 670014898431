import media from "@pagepro-monorepo/ui/lib/theme/config/media";
import styled, { css } from "styled-components";
import { Inner as UIInner } from "@pagepro-monorepo/ui/lib/components/styles/Grid";

import Link from "@components/atoms/Link";
import { LogoSygnet } from "@assets/svg";

import {
  StyledHeaderNavigationProps,
  StyledHeaderProps
} from "./types";
import { ListItem } from "./partials/HeaderNavigation/styles";

export const Wrapper = styled.div<StyledHeaderProps>`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndices.header};
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  min-height: ${({ theme }) => theme.sizes.header};
  padding: 0.625rem 1rem 0.625rem 0;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.accent};
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.15);

  @media ${media.tablet} {
    transition: ${({ theme }) => theme.transition};
    background-color: ${({ theme, isLight, isScrolled }) =>
      isLight && !isScrolled ? "transparent" : theme.colors.white};
    box-shadow: ${({ isLight, isScrolled }) =>
      isLight && !isScrolled
        ? "none"
        : css`
      0 0.25rem 1rem rgba(0, 0, 0, 0.15);
      `};
    color: ${({ theme, isLight, isScrolled }) =>
      isLight && !isScrolled
        ? theme.colors.white
        : theme.colors.primary};
  }
`;

export const Inner = styled(UIInner).attrs({
  variant: "wide"
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${media.tablet} {
    padding: 0 1rem 0 2rem;
  }
`;

export const LogoLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};
  color: ${({ theme }) => theme.colors.primary};
  @media ${media.tablet} {
    color: inherit;
  }
  @media ${media.tablet} {
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const LongLogo = styled.span`
  display: block;

  svg {
    height: 2.25rem;
    width: 5.9281rem;
    fill: currentColor;
  }

  @media ${media.tablet} {
    display: none;
  }

  @media ${media.desktop} {
    display: block;
  }
`;

export const Sygnet = styled(LogoSygnet)`
  height: 1em;
  width: 0.5em;
  fill: currentColor;
  font-size: 2rem;
  display: none;
  @media ${media.tablet} {
    display: block;
  }
  @media ${media.desktop} {
    display: none;
  }
`;

export const Hamburger = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media ${media.tablet} {
    display: none;
  }
`;

export const Navigation = styled.nav<StyledHeaderNavigationProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  height: calc(100vh - ${({ theme }) => theme.sizes.header});
  padding: 1rem 0.5rem;
  transition: ${({ theme }) => theme.transition};
  transform: ${({ isOpen }) =>
    isOpen ? "translateX(0)" : "translateX(100%)"};
  transition-delay: ${({ isOpen, itemsCount }) =>
    isOpen ? 0 : `${itemsCount * 150}ms`};
  ${({ isOpen }) =>
    isOpen &&
    css`
      overflow-y: auto;
      overflow-x: hidden;
      ${ListItem} {
        transform: none;
        opacity: 1;
      }
    `};

  @media ${media.tablet} {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0;

    position: static;
    top: auto;
    left: auto;
    right: auto;
    height: auto;
    background-color: transparent;
    transform: none;
    transition: none;
  }
`;

export const ControlsWrapper = styled.div`
  flex-shrink: 0;
  padding-left: 1rem;
`;
