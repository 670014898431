import React from "react";
import { storyblokEditable } from "@storyblok/react";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";

import { FooterLinkProps } from "./types";
import * as Styled from "./styles";

const FooterLink: React.FC<FooterLinkProps> = ({ blok }) => {
  const {
    label,
    link: {
      url,
      cached_url: cachedUrl,
      rel,
      target,
      story: { full_slug: fullSlug = "" } = {}
    }
  } = blok;
  const newestUrl = fullSlug || cachedUrl;
  const href = url || newestUrl;

  return (
    <Styled.Link
      {...{ href, rel, target, ...storyblokEditable(blok) }}
    >
      <Typography variant="body6" as="span">
        {label}
      </Typography>
    </Styled.Link>
  );
};

export default FooterLink;
