import React from "react";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";
import { elementMap } from "@pagepro-monorepo/ui/lib/components/styles/Typography/consts";
import {
  render,
  NODE_HEADING,
  MARK_LINK,
  RenderOptions,
  NODE_PARAGRAPH,
  NODE_UL,
  NODE_OL,
  MARK_STYLED,
  NODE_HR,
  NODE_IMAGE
} from "storyblok-rich-text-react-renderer";
import { TType } from "@pagepro-monorepo/ui/lib/components/styles/Typography/types";

import { MultilinkStoryblok } from "@components/storyblok/types";
import { getImage } from "@utils/getImage";
import { getFirstSpanVariantFromChildren } from "@utils/getFirstSpanVariantFromChildren";
import Image from "@components/atoms/Image";

import * as Styled from "./styles";
import { RichTextProps } from "./types";

const variantMap: Record<number, TType> = {
  6: "subheading1"
};

const serializers: RenderOptions = {
  nodeResolvers: {
    [NODE_HEADING]: (children, { level }) => {
      const firstLineVariant = Array.isArray(children)
        ? // eslint-disable-next-line react/destructuring-assignment
          children[0]?.props?.variant ||
          // eslint-disable-next-line react/destructuring-assignment
          children[0]?.props?.children?.props?.variant
        : undefined;

      const variant = variantMap[level] || `heading${level}`;
      const as = elementMap[variant];

      const headingVariant = firstLineVariant || variant;

      return (
        <Styled.Heading variant={headingVariant} forwardedAs={as}>
          {children}
        </Styled.Heading>
      );
    },
    [NODE_PARAGRAPH]: (children) => {
      const variant = getFirstSpanVariantFromChildren(children);

      return (
        <Typography as="p" {...{ variant }}>
          {children}
        </Typography>
      );
    },
    [NODE_UL]: (children) => (
      <Styled.UnorderedList>{children}</Styled.UnorderedList>
    ),
    [NODE_OL]: (children) => (
      <Styled.OrderedList>{children}</Styled.OrderedList>
    ),
    [NODE_HR]: () => <Styled.Line />,
    [NODE_IMAGE]: (children, { src, alt }) => {
      const image =
        src &&
        getImage({
          filename: src,
          alt
        });

      return image ? <Image {...image} /> : null;
    }
  },
  markResolvers: {
    [MARK_STYLED]: (children, props) => (
      // eslint-disable-next-line react/destructuring-assignment
      <Typography as="span" variant={props?.class}>
        {children}
      </Typography>
    ),
    [MARK_LINK]: (children, props: MultilinkStoryblok) => {
      const {
        href,
        linktype,
        target,
        anchor,
        custom,
        story: { full_slug: fullSlug = "" } = {}
      } = props;

      const newestUrl = fullSlug || href;
      const link = anchor
        ? `${newestUrl}#${anchor}`
        : newestUrl || "";

      if (!href) {
        return null;
      }

      if (linktype === "email") {
        return (
          <Styled.Link href={`mailto:${href}`} {...{ target }}>
            {children}
          </Styled.Link>
        );
      }

      const linkProps = {
        rel: custom?.rel,
        href: link,
        target
      };

      return (
        <Styled.Link {...{ ...linkProps }}>{children}</Styled.Link>
      );
    }
  },
  defaultStringResolver: (str) => <Typography>{str}</Typography>
};

const RichText: React.FC<RichTextProps> = ({
  content,
  mobileTextAlignment,
  tabletTextAlignment,
  desktopTextAlignment,
  underlineType
}) => (
  <Styled.Content
    {...{
      mobileTextAlignment,
      tabletTextAlignment,
      desktopTextAlignment,
      underlineType
    }}
  >
    {render(content, serializers)}
  </Styled.Content>
);

export default RichText;
