import styled, { css } from "styled-components";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";

import { IconChevronDown } from "@assets/svg";

import { StyledFooterMenuProps } from "./types";

export const Line = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  margin: ${({ theme }) => `${theme.space.XXS} 0 ${theme.space.XS}`};
  display: none;
  @media ${media.mobile} {
    display: block;
  }
`;

export const List = styled(Typography).attrs({
  forwardedAs: "ul",
  variant: "body6"
})`
  padding-bottom: ${({ theme }) => theme.space.XS};
  @media ${media.mobile} {
    padding-bottom: 0;
  }
`;

export const Toggler = styled.button`
  padding: ${({ theme }) => `${theme.space.XXS} 0`};
  background-color: transparent;
  border: 0;
  outline: none;
  color: inherit;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media ${media.mobile} {
    display: none;
    pointer-events: none;
    padding: 0;
  }
`;

export const Title = styled(Typography).attrs({
  variant: "body2",
  forwardedAs: "span",
  fontWeight: "semibold"
})`
  display: none;

  @media ${media.mobile} {
    display: block;
  }
`;

export const ToggleIcon = styled(IconChevronDown)`
  display: block;
  fill: currentColor;
  transition: ${({ theme }) => theme.transition};
  @media ${media.mobile} {
    display: none;
  }
`;

export const ListWrapper = styled.div`
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: ${({ theme }) => theme.transition};
  @media ${media.mobile} {
    max-height: unset;
    overflow: auto;
  }
`;

export const Wrapper = styled.div<StyledFooterMenuProps>`
  width: 100%;
  ${({ isOpen }) =>
    isOpen &&
    css`
      ${ListWrapper} {
        max-height: 31.25rem;
      }
      ${ToggleIcon} {
        transform: rotate(180deg);
      }

      @media ${media.mobile} {
        ${ListWrapper} {
          max-height: unset;
        }
        ${ToggleIcon} {
          transform: rotate(0deg);
        }
      }
    `}
`;
