import React, { PropsWithChildren } from "react";

import Header from "@components/organisms/Header";
import Footer from "@components/organisms/Footer";
import useHeaderMode from "@contexts/HeaderContext/hooks";
import LazyHydrate from "@components/utils/LazyHydrate";

import * as Styled from "./styles";
import { MainLayoutProps } from "./types";

const MainLayout: React.FC<PropsWithChildren<MainLayoutProps>> = ({
  headerProps,
  footerProps,
  children
}) => {
  const { isLight = false } = headerProps || {};

  useHeaderMode(isLight);

  return (
    <Styled.Wrapper withPadding={!isLight}>
      {headerProps && <Header {...headerProps} />}
      <Styled.Main>{children}</Styled.Main>
      {footerProps && (
        <LazyHydrate
          id="footer"
          useDisplayContents={false}
          noWrapper="footer"
          whenVisible
          getEventTarget={() => window}
        >
          <Footer {...footerProps} />
        </LazyHydrate>
      )}
    </Styled.Wrapper>
  );
};

export default MainLayout;
