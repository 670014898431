import { useState, useEffect } from "react";

import { noop } from "@utils/noop";
import { GTM_DEBUG_QUERY_PARAM } from "@hooks/useHrefWithGTMDebug/consts";

export const useDynamicChunksPreload = (): void => {
  const [preloadEnabled, setPreloadEnabled] = useState(false);

  useEffect(() => {
    const enablePreload = (): void => {
      setPreloadEnabled(true);
    };

    window.addEventListener("scroll", enablePreload);
    document.addEventListener("touchmove", enablePreload);
    document.body.addEventListener("mouseover", enablePreload);

    return (): void => {
      window.removeEventListener("scroll", enablePreload);
      document.removeEventListener("touchmove", enablePreload);
      document.body.removeEventListener("mouseover", enablePreload);
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    const dynamicCalls = window.__NEXT_DYNAMIC_CALLS__;

    if (!preloadEnabled || !dynamicCalls) {
      return noop;
    }

    return dynamicCalls.subscribe((dynamicCallsRefs) => {
      dynamicCallsRefs.forEach((dynamicCallRef) => {
        dynamicCallRef.render.preload();
      });
    });
  }, [preloadEnabled]);
};

export const useIsGtmDebug = (): boolean => {
  const [isGtmDebug, setIsGtmDebug] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const gtmDebug = urlParams.get(GTM_DEBUG_QUERY_PARAM);

    setIsGtmDebug(!!gtmDebug);
  }, []);

  return isGtmDebug;
};
