import { ImageProps } from "next/image";

import { AssetStoryblok } from "@components/storyblok/types";

export const getImage = (
  image: Omit<AssetStoryblok, "name"> | undefined
): ImageProps | undefined => {
  if (!image || !image.filename) {
    return undefined;
  }

  return {
    alt: image.alt ?? "alt",
    src: image.filename,
    height: image.filename?.split("/")[5].split("x")[1],
    width: image.filename?.split("/")[5].split("x")[0]
  };
};
