import styled, { css } from "styled-components";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";

import LinkComponent from "@components/atoms/Link";

import { StyledRichTextContentProps } from "./types";

export const Content = styled.div<StyledRichTextContentProps>`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  p,
  img {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  position: relative;

  ${({ mobileTextAlignment }) =>
    mobileTextAlignment &&
    css`
      text-align: ${mobileTextAlignment};
    `}
  ${({ tabletTextAlignment }) =>
    tabletTextAlignment &&
    css`
      @media ${media.mobile} {
        text-align: ${tabletTextAlignment};
      }
    `}
    ${({ desktopTextAlignment }) =>
    desktopTextAlignment &&
    css`
      @media ${media.tablet} {
        text-align: ${desktopTextAlignment};
      }
    `}

    ${({ underlineType }) =>
    underlineType === "blackGradient" &&
    css`
      u {
        text-decoration: none;
        background: ${({ theme: { colors } }) =>
          `linear-gradient(${colors.black}, ${colors.black}) 0 90% / 100% 0.5em no-repeat`};
        padding: 0 0.375rem;
      }
    `}
`;

export const Link = styled(LinkComponent)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  padding-bottom: 1px;
  transition: ${({ theme }) => theme.transition};
  transition-property: color, border-color;

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
    border-color: ${({ theme }) => theme.colors.accent};
  }
`;

export const Heading = styled(Typography)`
  strong,
  b {
    color: ${({ theme }) => theme.colors.accent};
    font-weight: inherit;
  }
  u {
    text-decoration: none;
    background: ${({ theme: { colors } }) =>
      `linear-gradient(${colors.accent}, ${colors.accent}) 0 90% / 100% 0.5em no-repeat`};
    padding: 0 0.375rem;
  }
`;

export const Line = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.colors.black};
  opacity: 0.2;
  margin: 1.25rem 0;
`;

export const UnorderedList = styled.ul`
  list-style: disc;
  padding-inline-start: 1.75rem;
`;

export const OrderedList = styled.ol`
  list-style: decimal;
  padding-inline-start: 1.75rem;
`;
