import React from "react";

import Link from "@components/atoms/Link";
import useHash from "@hooks/useHash";

import { HeaderSubmenuLinkProps } from "../types";
import * as Styled from "../styles";

const HeaderSubmenuLink: React.FC<HeaderSubmenuLinkProps> = ({
  item: { scrollToId, label },
  isScrolled
}) => {
  const hash = useHash();

  const isActive = hash === scrollToId;

  return (
    <Styled.ListItem {...{ isActive, isScrolled }}>
      <Link href={`#${scrollToId}`}>{label}</Link>
    </Styled.ListItem>
  );
};

export default HeaderSubmenuLink;
