import styled from "styled-components";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";

import BaseLink from "@components/atoms/Link";

export const Link = styled(BaseLink)`
  color: inherit;
  cursor: pointer;

  @media ${media.tablet} {
    transition: ${({ theme }) => theme.transition};
    &:hover {
      color: ${({ theme }) => theme.colors.accent};
    }
  }
`;
