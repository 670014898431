import styled from "styled-components";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";

import LinkComponent from "@components/atoms/Link";

export const Link = styled(LinkComponent)`
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${media.tablet} {
    transition: ${({ theme }) => theme.transition};
    &:hover {
      color: ${({ theme }) => theme.colors.accent};
    }
  }
  svg {
    fill: currentColor;
    font-size: 1rem;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
