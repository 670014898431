import React, {
  PropsWithChildren,
  useEffect,
  useRef,
  useState
} from "react";
import ReactDOM from "react-dom";
import FocusTrap from "focus-trap-react";

import { uuidv4 } from "@utils/lodash";

import {
  useBlockBodyWhenModalIsOpened,
  useModalContainer,
  useModalContextValue
} from "./hooks";
import * as Styled from "./styles";
import { ModalBaseProps } from "./types";
import { ModalContext } from "./consts";

const ModalProvider: React.FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const [modalContainerHasChildren, setModalContainerHasChildren] =
    useState(false);
  const { modalContextValue, modalContainerRef } =
    useModalContextValue();

  useBlockBodyWhenModalIsOpened(modalContainerRef.current);
  useEffect(() => {
    const modalNode = modalContainerRef.current;

    const observerCallback = (mutations: MutationRecord[]): void => {
      mutations.forEach((mutation) => {
        setModalContainerHasChildren(
          mutation.type === "childList" &&
            !!mutation.target.childNodes.length
        );
      });
    };

    const observer = new MutationObserver(observerCallback);

    if (modalNode) {
      observer.observe(modalNode, { childList: true });
    }

    return (): void => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Styled.Modal $isVisible={modalContainerHasChildren}>
        <div ref={modalContainerRef} />
      </Styled.Modal>
      <ModalContext.Provider value={modalContextValue}>
        {children}
      </ModalContext.Provider>
    </>
  );
};

const ModalBase: React.FC<PropsWithChildren<ModalBaseProps>> = ({
  children,
  onClose
}) => {
  const modalNode = useModalContainer();
  const focusTrapInnerWrapperIdRef = useRef<string>(
    `focus-trap-wrapper-${uuidv4()}`
  );

  useEffect(() => {
    const closeOnEscKeyPress = (e: KeyboardEvent): void => {
      if ((e.key === "Escape" || e.key === "27") && onClose) {
        onClose();
      }
    };

    window.addEventListener("keydown", closeOnEscKeyPress, {
      passive: true
    });

    return () =>
      window.removeEventListener("keydown", closeOnEscKeyPress);
  }, [onClose]);

  return modalNode
    ? ReactDOM.createPortal(
        <FocusTrap
          focusTrapOptions={{
            fallbackFocus: `#${focusTrapInnerWrapperIdRef.current}`
          }}
          active
        >
          <Styled.ContentWrapper
            id={focusTrapInnerWrapperIdRef.current}
          >
            <Styled.Overlay
              onClick={(e) => {
                e.stopPropagation();
                if (onClose) {
                  onClose();
                }
              }}
            />
            <Styled.Content>{children}</Styled.Content>
          </Styled.ContentWrapper>
        </FocusTrap>,
        modalNode
      )
    : null;
};

export { ModalProvider, ModalBase as default };
