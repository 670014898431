import React, { PropsWithChildren, useMemo, useState } from "react";

import { noop } from "@utils/noop";

import { HeaderModeContextValue } from "./types";

export const HeaderModeContext =
  React.createContext<HeaderModeContextValue>({
    isHeaderTransparent: false,
    setIsHeaderTransparent: noop
  });

export const { Provider: HeaderModeProvider } = HeaderModeContext;

export const HeaderModeContextProvider: React.FC<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const [isHeaderTransparent, setIsHeaderTransparent] =
    useState(false);
  const headerModeContextValue = useMemo(
    () => ({
      isHeaderTransparent,
      setIsHeaderTransparent
    }),
    [isHeaderTransparent, setIsHeaderTransparent]
  );

  return (
    <HeaderModeProvider value={headerModeContextValue}>
      {children}
    </HeaderModeProvider>
  );
};
