import React from "react";
import { StoryblokComponent } from "@storyblok/react";
import {
  Box,
  FlexBox,
  GridBox,
  Inner
} from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";

import Clutch from "@components/molecules/Clutch";

import { FooterProps } from "./types";
import * as Styled from "./styles";
import FooterPartnerships from "./partials/FooterPartnerships";

const Footer: React.FC<FooterProps> = ({
  title,
  clutchLink,
  clutchRate,
  clutchReviewsAmount,
  buttonProps,
  items,
  legalLinks,
  socialMediaItems,
  backgroundColor,
  color,
  partnerships,
  partnershipsTitle = ""
}) => (
  <Styled.Wrapper {...{ backgroundColor, color }}>
    <Inner>
      <Styled.Header>
        {typeof title === "string" ? (
          <Typography variant="body8">{title}</Typography>
        ) : (
          title
        )}
        {buttonProps && (
          <FlexBox display={["block", "none"]} mb="S">
            <StoryblokComponent blok={buttonProps} />
          </FlexBox>
        )}
        {clutchRate ? (
          <Clutch
            rate={clutchRate}
            reviewsAmount={clutchReviewsAmount}
            link={clutchLink}
          />
        ) : null}
      </Styled.Header>
      {buttonProps && (
        <FlexBox display={["none", "block"]} mb={["M", "M", "L"]}>
          <StoryblokComponent blok={buttonProps} />
        </FlexBox>
      )}
      {items?.length ? (
        <Box mb={["M", "M", "L"]}>
          <GridBox
            as="ul"
            gridTemplateColumns={[
              "1fr",
              "repeat(2, 1fr)",
              "repeat(5, 1fr)"
            ]}
            gridColumnGap={["", "8.4375rem", "2rem"]}
            gridRowGap={["", "2rem", "2rem"]}
          >
            {items.map((item) => (
              <Styled.ListItem key={item._uid}>
                <StoryblokComponent blok={item} />
              </Styled.ListItem>
            ))}
          </GridBox>
        </Box>
      ) : null}
      {partnerships?.length ? (
        <FooterPartnerships
          assets={partnerships}
          title={partnershipsTitle}
        />
      ) : null}
      <FlexBox
        alignItems={["flex-start", "", "center"]}
        justifyContent="space-between"
        flexDirection={["column-reverse", "row"]}
      >
        <FlexBox
          as="ul"
          alignItems="center"
          flexWrap="wrap"
          m={["0 -0.5rem", "", "0 -1rem"]}
          pr={[0, "", "2rem"]}
        >
          {legalLinks?.length
            ? legalLinks.map((item) => (
                <Box
                  key={item._uid}
                  p={["0.5rem", "", "1rem"]}
                  as="li"
                >
                  <StoryblokComponent blok={item} />
                </Box>
              ))
            : null}
          <Box p={["0 0.5rem", "", "0 1rem"]} as="li">
            <Typography variant="body6">
              {new Date().getFullYear()} &copy; All rights reserved.
              Pagepro Ltd.
            </Typography>
          </Box>
        </FlexBox>
        {socialMediaItems?.length ? (
          <GridBox
            as="ul"
            alignItems="center"
            gridTemplateColumns={`repeat(${socialMediaItems.length}, auto)`}
            gridGap="1.5rem"
            mb={["S", "S", 0]}
          >
            {socialMediaItems.map((item) => (
              <li key={item._uid}>
                <StoryblokComponent blok={item} />
              </li>
            ))}
          </GridBox>
        ) : null}
      </FlexBox>
    </Inner>
  </Styled.Wrapper>
);

export default Footer;
