import { useEffect } from "react";

import { HeaderSubmenuProps } from "./types";

const useHeaderSubmenuObserver = (
  submenu: HeaderSubmenuProps["submenu"] = []
) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries.find((e) => e.isIntersecting);

        if (entry) {
          window.history.replaceState(
            {},
            "",
            `#${entry.target.children[0]?.id}`
          );
          window.dispatchEvent(new HashChangeEvent("hashchange"));

          return;
        }

        if (!entry && entries.length === 1) {
          window.history.replaceState(
            {},
            "",
            window.location.pathname
          );
          window.dispatchEvent(new HashChangeEvent("hashchange"));
        }
      },
      { rootMargin: "-50% 0px" }
    );

    submenu.forEach(({ scrollToId }) => {
      const target = document.querySelector(`#${scrollToId}`);

      if (target?.parentElement) {
        observer.observe(target.parentElement);
      }
    });

    return () => observer.disconnect();
  }, [submenu]);
};

export default useHeaderSubmenuObserver;
