import React, { useLayoutEffect, useState } from "react";
import { ReactSVG } from "react-svg";

import { ButtonStoryblok } from "@components/storyblok/types";
import { tryToReplaceDomain } from "@utils/tryToReplaceDomain";

import * as Styled from "./styles";

const StickyButton = (props: ButtonStoryblok) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useLayoutEffect(() => {
    const footerHeight =
      document.getElementById("footer")?.clientHeight ?? 0;

    const onScroll = () => {
      const isTopOffset = window.scrollY > window.innerHeight;
      const isBottomOffset =
        window.scrollY <
        window.document.body.offsetHeight -
          window.innerHeight -
          footerHeight;

      setIsScrolled(isTopOffset && isBottomOffset);
    };

    onScroll();

    window.addEventListener("scroll", onScroll, { passive: true });

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const {
    label,
    link: {
      url,
      cached_url: cachedUrl,
      email,
      rel,
      target,
      story: { full_slug: fullSlug = "" } = {}
    } = {},
    icon: svgIcon
  } = props;

  const newestUrl = fullSlug || cachedUrl;
  const href = email ? `mailto:${email}` : url || newestUrl;
  const Icon = svgIcon?.filename
    ? tryToReplaceDomain(svgIcon?.filename)
    : undefined;

  return (
    <Styled.StickyButtonLink
      className="ga-cta-sticky"
      $mode="primarySimple"
      {...{
        href,
        rel,
        target,
        isScrolled
      }}
    >
      {Icon && <ReactSVG src={Icon} />}
      <span>{label}</span>
    </Styled.StickyButtonLink>
  );
};

export default StickyButton;
